/*The header will be fixed at the top of every page. It needs to transition from having a background color to being transparent. The logo will overhang the header by about 25% and be fixed to the top left of the page. The navigation bar will be fixed to the top right of the page.
*/

#App-header {
    /* border: solid blue; */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    height: 110px;
    background: linear-gradient(to bottom, #bfbece, #e9ebf2);
    box-shadow: 0 4px 6px rgba(140, 132, 154, 0.934);
    animation: shadow-pulse 2s ease-in-out infinite alternate;
    z-index: 5;
}

@keyframes shadow-pulse {
    0% {
      box-shadow: 0 4px 6px rgba(140, 132, 154, 0.934);
    }
    100% {
      box-shadow: 0 6px 12px rgba(140, 132, 154, 0.934);
    }
  }

.container {
    display: flex;    
}

#logoCont{
    width: 33%;
    display: flex;
    position: relative;
    justify-content: center;    
}
#logo {
    position: absolute;
    padding-top: 40px;
    width: 150px;
    overflow: visible;
    filter: drop-shadow(0 -2px 5px rgba(0, 0, 0, 0.5));
}

#headright {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

#titleCont{
  width: 100%;
}

#title{
  margin: 0 auto;
  margin-top: 1vh;
  font-size: 3rem;
}

#navCont{
    width: 100%;
    display: flex;
    justify-content: center;
}

/* Legacy Devices small smartphones */
@media (max-width:494px) {
  #App-header {
    flex-direction: column;
    height: 25.5vh;
  }
  #logoCont{
    width: 100%;
    display: flex;
    justify-content: center;
  }
  #logo {
    padding: 40px 0;
    width: 100px;
  }
  #title {    
    margin-top: 20px;
    font-size: 1.2rem;
  } 
  #navCont{
    width: 90%;
    display: flex;
    justify-content: center;
    padding: 5px;
  }
  #navList {
    padding-inline-start: 0;
  }
  .navItem {
    font-size: 1rem;
  }
}

/* Small devices (phones) */
@media (min-width: 495px) and (max-width: 576px) {
  #App-header {
    height: 62.5px;
  }
  #logo {
    padding-top: 20px;
    width: 80px;
  }
  #title {    
    margin-top: 10px;
    font-size: 1.2rem;
  } 
  #navCont{
    width: 100%;
    display: flex;
    justify-content: center;
    padding-inline-start: 0;
  }
}

/* Medium devices (tablets) */
@media (min-width: 577px) and (max-width: 768px) {
  #App-header {
    height: 85px;
  }
  #logo {
    padding-top: 37.5px;
    width: 100px;
  }
  #title {    
    margin-top: 1vh;
    font-size: 1.35rem;
  } 
  #navCont{
    width: 100%;
    display: flex;
    justify-content: center;
    padding-inline-start: 70px;
  }
}

/* Large devices (desktops) */
@media (min-width: 769px) and (max-width: 992px) {
  #logo {
    padding-top: 20px;
    width: 130px;    
  }
  #title {    
    margin-top: 2vh;
    font-size: 1.65rem;
  } 
  #navCont{
    width: 100%;
    display: flex;
    justify-content: center;
    padding-inline-start: 20px;
  }
}