.App {
  text-align: center;
  background: linear-gradient(to bottom, rgb(27, 26, 42), rgb(5, 5, 10));
  height: 100%;
  width: 100%;
}
.head2 {
  margin-block: 0;
  color: white;
  padding-bottom: 5.5vh;
  padding-top: 2.5vh;
}
.head3{
  margin-block: 0;
  color: white;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 576px) {
  .head2 {
    padding-bottom: 2.5rem;
    padding-top: 1.75rem;
  }
}

/* Large mobile devices (smartphones) */
@media (min-width: 577px) and (max-width: 768px) {
  .head2 {
    padding-bottom: 5.5rem;
    padding-top: 1rem;
  }
}

/* Medium devices (ipad/laptop/narrow windowed) */
@media (min-width: 769px) and (max-width: 992px) {
  .head2 {
    padding-bottom: 0;
    padding-top: 2.5rem;
  }
  .services {
    margin: 60px 0;
  }
}
