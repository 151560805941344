/*body.css is only responsible for the container of the body and its elemtents*/

.Body{
    max-width: 75%;
    margin: 100px auto;
    min-height: 100%;
    padding: 30px 0;
    z-index: -1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.bodCont {
    border-radius: 15px;
    margin: 10px 0;
    animation: glow 2s ease-in forwards, tv-on 1.5s ease-in-out forwards;
}

.servCont{
    margin: 0 auto;
    border: solid blue;
}
 @keyframes glow {
    0% {
        box-shadow:rgba(246, 246, 246, 0)
    }
    100% {
        box-shadow:0 0 20px rgba(178, 198, 232, 0.889)
    }
} 
@keyframes tv-on {
    0% {
        width: 0%;
        height: 0%;
        opacity: 0;
        transform-origin: center center;
        transform: scaleY(0);
        border-radius: 100px;
        filter: blur(20px);
        background-color: rgba(195, 196, 197, 0.1);
        top: 0%;
        left: 50%;
    }
    50%{
        width: 400px;
        height: 25%;
        opacity: .5;
        transform-origin: center center;
        transform: scaleY(1);
        border-radius: 100px;
        filter: blur(20px);
        background-color: rgba(195, 196, 197, 0.27);
        top: 25%;
        left: 50%;
        /* transform: translate(-50%, -50%); */
    }
    100% {
        width:80%;
        height: 100%;
        opacity: 1;
        transform-origin: center center;
        transform: scaleY(1);
        background-color: rgba(195, 196, 197, 0.517);
        top: 50%;
        left: 50%;
        /* transform: translate(-50%, -50%);     */
    }
}

/* Legacy Decices (Small Smartphones) */
@media (max-width: 494px) {
    .Body{
        max-width: 100%;
        margin: 100px auto;
        min-height: 100%;
        padding: 5px 0;
        z-index: -1;
    }
    .head2 {
        display: none;
    }
    .bodCont {
        width: 90%;
        margin-top: 150px;
        padding: 5px;
        margin-bottom: 10px;
    }
    
    #am p{
        font-size: 1.1rem;
    }
}
/* Small devices (phones) */
@media (min-width: 495px) and (max-width: 576px) {
    .Body{
        max-width: 100%;
        margin: 100px auto;
        min-height: 100%;
        padding: 5px 0;
        z-index: -1;
    }
    .bodCont {
        width: 90%;
        margin-top: 60px;
        padding: 5px;
        margin-bottom: 20px;
    }
    
}
  
/* Medium devices (tablets) */
@media (min-width: 577px) and (max-width: 768px) {
    .Body{
        width: 100%;
        margin: 0 auto;
    }
    .bodCont {
        width: 300px;
        margin-top: 50px;
        padding-bottom: 20px;
    }
    
}
  
/* Large devices (desktops) */
@media (min-width: 769px) and (max-width: 992px) {
    .Body{
        max-width: 90%;
        margin: 100px auto;
        padding: 5px 0;
        z-index: -1;
    }
    .bodCont {
        margin-top: 50px;
        margin-bottom: -50px;
        height: 2000px;
    }
}