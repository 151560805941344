#navigation {
    padding: 0;
    margin: 0;
}

#navList {
    max-width: 1000px;
    display: flex;
    justify-items: flex-end;
    font-size: 20px;
    font-weight: bold;
    margin: 0;
}

.navItem {
    list-style-type: none;
}
.navItem a {
    color: rgba(110, 2, 242, 0.769);
    text-decoration: none;
    padding: 0 10px;
}

.navImg{
    display: none;
}

/* Small devices (phones) */
@media (max-width: 576px) {
    #navList {
        margin-top: 0px;
    }
  }
  
  /* Medium devices (tablets) */
  @media (min-width: 577px) and (max-width: 768px) {
    /* CSS rules for medium devices here */
  }
  
  /* Large devices (desktops) */
  @media (min-width: 769px) and (max-width: 992px) {
    /* CSS rules for large devices here */
    #navList {
        margin: 0;
    }
  }