#servList {
    display: flex;
    flex-direction: row;
    height: auto;
    margin-block: 0;
    padding-inline: 0;
    animation: fade-in 2.5s ease-in forwards;
    z-index: 3;
}

#servList > .servItem {
    width: calc(33.33% - 1px);
    padding-right: 2.5px;
    padding-left: 2.5px; 
}

#servList > .servItemrow-reverse {
    display: flex;
    flex-direction: row-reverse;
    width: 100%; 
}

.servImg {
    padding-top: 15px;
    border-radius: 35px;
    width: 80%;
}

.servHeading {
    padding: 0;
    color: aliceblue
}

/* Legacy Devices (Small Smartphones) */
@media (max-width: 494px) {
    
    #servList {
        padding: 0px;
        align-items: center;
        /* margin: -75px 40px 10px; */
        flex-direction: column;
    }
    #servList > .servItem {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 80%;
        padding: 0;
        height: auto;
    }
    #servList > .servItemrow-reverse {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        width: 80%;
        padding: 0;
        height: auto;
    }
    #servCard {
        width: 80%;
    }
    .servImg {
        padding-top: 15px;
        border-radius: 35px;
        max-width: 20%;
        height: auto;
    }
}

/* Small devices (phones) */
@media (min-width: 495px) and (max-width: 576px) {
    #servList {
        padding: 10px;
        margin: -75px 40px 10px;
        flex-direction: column;
    }
    #servList > .servItem {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding: 0;
        height: auto;
    }
    .servImg {
        padding-top: 15px;
        border-radius: 35px;
        width: 50%;
    }

  }
  
/* Medium devices (tablets) */
@media (min-width: 577px) and (max-width: 768px) {

    #servList {
        display: flex;
        width:100%;
        margin: 0 auto;
        padding: 5px;
        flex-direction: column;
    }
    
    #servList > .servItem {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding: 0;
        height: auto;
    }
    
    .servImg {
        padding-top: 15px;
        border-radius: 35px;
        width: 50%;
    }
}
  
/* Large devices (desktops) */
@media (min-width: 769px) and (max-width: 992px) {
    #servList{
        margin: 5px;
    }
}
  