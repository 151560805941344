#am {
    display: flex;
    padding: 0 25px;
    opacity: 0;
    animation: fade-in 2.5s ease-in forwards;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    50%{
        opacity:0;
    }
    100% {
        opacity: 1;
    }
}

p {
    font-size: 1rem;
    color:rgba(225, 241, 251, .75);
}

#am p {
    font-size: 1.5rem;
}

a {
    text-decoration: none;
    color:rgba(225, 241, 251, .75);
}

a:hover {
    text-decoration: underline;
}

/* Small devices (smart phones) */
@media (max-width: 576px) {

}
  
/* Medium devices (tablets) */
@media (min-width: 577px) and (max-width: 768px) {
   
}
  
/* Large devices (desktops) */
@media (min-width: 769px) and (max-width: 992px) {
  /* CSS rules for large devices here */
}