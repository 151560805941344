.servBody{
    max-width: 75%;
    margin: 100px auto;
    min-height: 100%;
    padding: 30px 0;
    z-index: -1;
}

#servHead {
    padding-bottom: 2.5rem;
}

.servText {
    position: relative;
    max-width: 80%;
    height: auto;
    margin: 0 auto;
}

.servText::before{
    content: "";
    position: absolute;
    left: 0;
    top: -10%;
    right: 10%;
    left: 10%;
    bottom: 10%;
    width: 80%;
    height: 2px;
    background: linear-gradient(to right, transparent, rgba(203, 229, 245, 0.75), transparent);
}

#coreServ {
    margin-top: 0px;
}

#servCont {
    display: flex;
    justify-content: space-evenly;
    margin: 40px auto 20px;
    width: 80%;
    height: auto;
}
.servSub {
    width: 300px;
    background-color: rgba(240, 248, 255, 0.724);
    border-radius: 15px;
    border: solid 4.5px rgb(16, 15, 20);
    box-shadow: 0 5px 5px 2.5px rgb(52, 46, 64);
}
.coreServ {
    text-align: center;
}
.servList {
    list-style:inside ;
    margin: 5%;
    padding-inline-start: 10px;
    text-align: left;;
}
@media (max-width: 576px) {
    #coreServ{
        margin-top: 20px;
        margin-bottom: 60px;
    }
    .servSub {
        margin-top: 0px;
    }
    #servCont {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 310px;
    }
  }

/* Legacy Devices (Small Smartphones) */
@media (max-width: 494px) {
    
    .servBody {
        margin: 200px 5px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        max-width: 100vw;
    }
    #coreServ{
        margin: 20px;
    }
    #servCont {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin: 20px;
        width: 100%;
        height: auto;
    }
}
  
/* Medium devices (tablets) */
@media (min-width: 577px) and (max-width: 768px) {
    #servCont {
        display: flex;
        justify-content: space-evenly;
        margin: 40px auto 20px;
        width: 100%;
        height: auto;
        border: solid blue 1px;
    }
    #coreServ{
        margin-top: 150px;
    }
    .coreServ {
        text-align: center;
    }
    .servList {
        list-style:inside ;
        margin: 5%;
        padding-inline-start: 10px;
        text-align: left;;
    }
}
  
/* Large devices (desktops) */
@media (min-width: 769px) and (max-width: 992px) {
     
}