*,
*::before,
*::after {
    box-sizing: border-box;
    max-width: 100vw;
}

.contactCont {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin: 100px 100px;
    padding: 30px 0;
}

#contactHead {

}

#contactBody {
    display: flex;
    flex-direction: row;
}

.contactText {

}

#contactFormCont {
    display: flex;
    flex-direction: column;
    height: 40vh;
    width: 40vw;
    align-items: center;
    margin: 10px 0;
}

#phoneEmail ul {
    color: white;
    list-style-type: none;
    display: flex;
    flex-direction: row;
}

#phoneEmail li {
    margin: 10px;
}

#contactForm {
    display: flex;
    flex-direction: column;
    color: white;
}

.formInput {
    display: flex;
    justify-content: space-evenly;
    margin: 10px;
}

#name {
    width: 10vw;
    align-self: end;
}

#email {
    width: 10vw;
    align-self: end;
}

#message {
    height: 20vh;
    width: 30vw;
}

#contSubmit {
    width: 10vw;
    align-self: end;
    margin: 10px 0;
}

/* Legacy Devices small smartphones */
@media (max-width:494px) {
    #contactHead {
        display: none;
    }    
    .contactCont {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        margin: 230px 5px 0;
        padding: 0;
    }
    #contactBody {
        flex-direction: column;
        max-width: 100%;
        margin: 0;
    }
    .babble {
        max-width: 100vw;
    }
    #contactFormCont {
        display: flex;
        flex-direction: column;
        height: 40vh;
        width: 100vw;
        align-items: center;
        margin: 10px 0;
    }
    #phoneEmail ul {
        flex-direction: column;
        align-items: center;
        padding-inline-start: 0;
        margin: 0;
    }
    #contactForm {
        width: 90vw;
    }
    .formInput {
        display: flex;
        justify-content: space-evenly;
        margin: 10px;
    }
    
    #name {
        width: 40vw;
        align-self: end;
    }
    
    #email {
        width: 40vw;
        align-self: end;
    }
    
    #message {
        height: 20vh;
        width: 90vw;
    }
    
    #contSubmit {
        width: 20vw;
        align-self: end;
        margin: 10px 0;
    }
  }
  
  /* Small devices (phones) */
  @media (min-width: 495px) and (max-width: 576px) {
    
  }
  
  /* Medium devices (tablets) */
  @media (min-width: 577px) and (max-width: 768px) {
    
  }
  
  /* Large devices (desktops) */
  @media (min-width: 769px) and (max-width: 992px) {
    
  }