/* Apply box-sizing to all elements */
*,
*::before,
*::after {
    box-sizing: border-box;
    max-width: 100vw;
}

.portCont {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin: 100px 100px;
    padding: 30px 0;
}

.portText {
    margin: 10px auto;
    width: 80vw;
    font-size: 20px;
    font-weight: bold;
}

.babble {
    max-width: 40vw;
}

#portProjs {
    width: 80vw;
    margin: 0 auto;
}

#portList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    height: auto;
    margin-block: 0;
    margin: 40px 0;
    padding-inline: 0;
    padding: 5px;
    animation: fade-in 2.5s ease-in forwards;
}

#portList li {
    max-width: 33%;
    margin: 10px 0;
}

.portItem {
    position: relative;
    margin-bottom: 20px;
}

.portItem::before{
    content: "";
    position: absolute;
    left: 0;
    top: -5%;
    right: 10%;
    left: 10%;
    bottom: 10%;
    width: 80%;
    height: 2px;
    background: linear-gradient(to right, transparent, rgba(203, 229, 245, 0.75), transparent);
}

.portHeading {
    padding: 0;
    color: aliceblue;
}

.portImg {
    max-width: 450px;
    display: block;
    margin: 0;
}

#portList .portText {
    margin: 10px auto;
    max-width: 450px;
    font-size: 16px;
    font-weight: 100;
}

/* Legacy Devices small smartphones */
@media (max-width:494px) {
    .portCont {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        margin: 200px 5px 0;
        padding: 0;
    }
    #portList {
        padding-inline-start: 0;
    }
    #portList li {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 80vw;
        padding: 0;
        height: auto;
    }
    .portImg {
        max-width: 100%;
        height: auto;
    }
  }
  
  /* Small devices (phones) */
  @media (min-width: 495px) and (max-width: 576px) {
    
  }
  
  /* Medium devices (tablets) */
  @media (min-width: 577px) and (max-width: 768px) {
    
  }
  
  /* Large devices (desktops) */
  @media (min-width: 769px) and (max-width: 992px) {
    
  }